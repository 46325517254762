import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {shortAddr, numFormat, getNameAddr, getAsset, shortAddress} from '../../../../../modules/index';
import moment from 'moment';
import iconIdent from '../iconIdent';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class Payments extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}



	formatDate(time) {
	  return moment(time).format("DD MMMM YYYY HH:mm:ss");
	}

	itemType(type) {
	  return type.replace(/_/g," ")[0].toUpperCase() + type.replace(/_/g," ").slice(1);
	}



	renderPathPayment = (item, index) => {

		const link = () => {
			return <Link to={item.source_asset_type === 'native' ? '/native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
		}

		return (
			<tr key={index}>
				<td><small>{index+1}</small></td>
				<td>
					<div><i className="fa fa-long-arrow-left text-danger"></i> From: { shortAddress(item.from) } <b className="text-info">{ numFormat(item.source_amount, 7) }</b>
						{link()}
					</div>
					<div><i className="fa fa-long-arrow-right text-success"></i> To:  { shortAddress(item.to) } <b className="text-info">{ numFormat(item.amount, 7) }</b> {getAsset(item)}</div>
					<div><i className="fa fa-random"></i> Path: {!item.path.length ? <span>[ {link()} -> {getAsset(item)} ]</span> : '' }
						{
							item.path.map((pathItem, index) => {
								return (
									<b key={ index }> {getAsset(pathItem)} { item.path.length > (index+1) ? '->' : '' }</b>
								)
							})
						}
					</div>
				</td>
				<td> <b className="text-info">{numFormat(item.amount, 7)}</b> { getAsset(item) }</td>
				<td><b className="text-info">{this.itemType(item.type)}</b></td>
				<td>{this.formatDate(item.created_at)}</td>
				<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
			</tr>
		)
	}



	renderItem(item, index) {

		switch(item.type_i) {
			case 0:
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						{/*<td><div> { item.funder === this.props.account_id ? 'To ' : 'From ' } { item.funder === this.props.account_id ? shortAddress(item.account) : shortAddress(item.funder) } </div></td>*/}
						<td><div>From {shortAddress(item.funder)} <i className="fa fa-long-arrow-right"></i> to {shortAddress(item.account)}</div></td>
						<td> <b className="text-info">{numFormat(item.starting_balance, 7)}</b> <Link to={'/native'}><b className="text-warning">XLM</b></Link></td>
						<td><b className="text-info">{this.itemType(item.type)}</b></td>
						<td>{this.formatDate(item.created_at)}</td>
						<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
					</tr>
				)

			case 1:
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><div>From { shortAddress(item.from) } <i className="fa fa-long-arrow-right"></i> to { shortAddress(item.to) }</div></td>
						<td> <b className="text-info">{numFormat(item.amount, 7)}</b> { getAsset(item) }</td>
						<td><b className="text-info">{this.itemType(item.type)}</b></td>
						<td>{this.formatDate(item.created_at)}</td>
						<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
					</tr>
				)

			case 2:
				return this.renderPathPayment(item, index)

			case 8:
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>NAN</td>
						<td>NAN</td>
						<td><b className="text-info">{this.itemType(item.type)}</b></td>
						<td>{this.formatDate(item.created_at)}</td>
						<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
					</tr>
				)

			case 13:
				return this.renderPathPayment(item, index)

			case 24: // "invoke_host_function"
				console.log('default operation', item);
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><div>{ shortAddress(item.source_account) } invoked contract { shortAddress(item.asset_balance_changes[0].to) }</div></td>
						<td>Balance changes: <b className="text-info">{numFormat(item.asset_balance_changes.length, 7)}</b></td>
						<td><b className="text-info">{this.itemType(item.type)}</b></td>
						<td>{this.formatDate(item.created_at)}</td>
						<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
					</tr>
				)

			default:
				console.log('default operation', item);

				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
					<td>Comming Soon</td>
					<td>Comming Soon</td>
					<td><b className="text-info">{this.itemType(item.type)}</b></td>
					<td>{this.formatDate(item.created_at)}</td>
					<td><Link to={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></Link></td>
					</tr>
				)
		}
	}


	render() {

		const items = this.props.items;

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
						<th>#</th>
			    	<th>Detail</th>
			    	<th>Amount</th>
			    	<th>Type</th>
			        <th>Date</th>
			        <th>Transaction</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ items.map(this.renderItem.bind(this)) }

			  </tbody>
			</Table>
		)
	}
}

Payments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Payments);
